import React, { useState } from "react";
import { Button, Modal, Table } from "reactstrap";

const CoursePriceModal = ({
  course,
  coursePriceModal = false,
  handleCoursePriceModal,
  updateCoursePrices,
}) => {
  const [state, setState] = useState({
    coursePrices: {},
  });
  const handlePriceChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setState((prev) => ({
      ...prev,
      coursePrices: {
        ...prev.coursePrices,
        [key]: value,
      },
    }));
  };

  return (
    <div>
      <Modal
        isOpen={coursePriceModal}
        className="modal-dialog-centered"
        toggle={(e) => {
          handleCoursePriceModal();
        }}
      >
        <div className="coursePricePerHourModal">
          <div className="pb-2">
            <h1 className="text-center">Edit Course Price Per Hour</h1>

            <h3 className="text-center">{`Subject: ${
              course?.courseName ?? "N/A"
            }`}</h3>
          </div>
          <div>
            <div
              style={{
                marginTop: "0.365vw",
                height: "33.26.5vw",
                overflowY: "scroll",
                marginTop: "41px",
              }}
              className="BillingInvoiceModalBoxTable PrimaryScrollQualification12121"
            >
              {/* style={{width:"100%", height:"766px"}} */}
              <Table
                className="align-items-center table-flush PrimaryScrollQualification12121"
                style={{ overflowY: "scroll" }}
                responsive
              >
                <thead
                  className="thead-light sticky-top"
                  // style={{ top: "-3px" }}
                  style={{
                    position: "absolute",
                    width: "23.40vw",
                    zIndex: "1000",
                    top: "6vw",
                  }}
                >
                  <tr>
                    <th
                      scope="col"
                      style={{
                        // paddingLeft: "1.563vw",
                        textAlign: "start",
                        fontSize: "0.625vw",
                        fontWeight: 700,
                        width: "10.325vw",
                      }}
                    >
                      Level Name
                    </th>
                    <th
                      scope="col"
                      style={{
                        // paddingRight: "1.563vw",
                        // textAlign: "end",
                        fontSize: "0.625vw",
                        fontWeight: 700,
                        width: "10.325vw",
                      }}
                    >
                      Min Rate
                    </th>

                    <th
                      scope="col"
                      style={{
                        // paddingRight: "1.563vw",
                        // textAlign: "end",
                        fontSize: "0.625vw",
                        fontWeight: 700,
                        width: "10.325vw",
                      }}
                    >
                      Max Rate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {course?.courseLevels?.map((cLevel, ind) => (
                    <tr
                      key={ind}
                      style={
                        {
                          // width: "22.40vw",
                        }
                      }
                    >
                      <td
                        style={{
                          fontWeight: "bold",
                          // width: "10.325vw",

                          // fontSize: "70%",
                        }}
                      >
                        {cLevel.level}
                      </td>

                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "70%",
                          // width: "10.325vw",
                        }}
                      >
                        <input
                          id={`courseLevels.${ind}.minPrice`}
                          name={`courseLevels.${ind}.minPrice`}
                          placeholder={cLevel?.minPrice}
                          className="coursePriceInput"
                          onChange={handlePriceChange}
                        />
                        {/* {`${cLevel?.minPrice}$ - ${cLevel?.maxPrice}$`} */}
                      </td>

                      <td
                        style={{
                          fontWeight: "bold",
                          fontSize: "70%",
                          // width: "10.325vw",
                        }}
                      >
                        <input
                          id={`courseLevels.${ind}.maxPrice`}
                          name={`courseLevels.${ind}.maxPrice`}
                          placeholder={cLevel?.maxPrice}
                          className="coursePriceInput"
                          onChange={handlePriceChange}
                        />
                        {/* {`${cLevel?.minPrice}$ - ${cLevel?.maxPrice}$`} */}
                      </td>

                      {/* <td /> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="text-center">
            <Button
              color="rgb(255, 103, 0)"
              className="updateCourseBtn"
              type="button"
              // disabled={isloading}
              onClick={() => {
                updateCoursePrices(course, state.coursePrices);
              }}
            >
              Update Course
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CoursePriceModal;
