import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  CardBody,
  FormGroup,
  Input,
  Container,
  Col,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "services/api";
import { isPropsEqual } from "@fullcalendar/react";
import "./index.css";
const localizer = momentLocalizer(moment);

const BankAccontDetail = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [message, setMessage] = useState("");
  const [bankDetail, setBankDetail] = useState([]);
  // const [userId, setUserId] = useState('');
  const { userId } = props;
  const [user, setUser] = useState("");
  const [selectedBankDetail, setSelectedBankDetail] = useState([]);
  const [approvedBankDetail, setApprovedBankDetail] = useState([]);

  useEffect(() => {
    getBankAccountDetails(userId);
  }, [userId]);

  async function getBankAccountDetails(userId) {
    try {
      const bankDetail1 = await api.getBankAccountDetails(`?tutorId=${userId}`);
      // if (!schedule.length) {
      await setBankDetail(bankDetail1?.data);
      // }
    } catch (e) {
      console.log(e.response);
    }
  }
  async function approveBankDetail(id, data) {
    try {
      const approvedBankDetail = await api.editBankDetail(id, data);
      await setApprovedBankDetail(approvedBankDetail.data);
      getBankAccountDetails(userId);
    } catch (e) {
      console.log(e.response);
    }
  }
  async function rejectBankDetail(id, data) {
    try {
      const approvedBankDetail = await api.editBankDetail(id, data);
      await setApprovedBankDetail(approvedBankDetail.data);
      getBankAccountDetails(userId);
    } catch (e) {
      console.log(e.response);
    }
  }

  function hashNumber(num) {
    let hash = "";
    for (let i = 0; i < num.length - 3; i++) {
      hash = hash + "*";
    }
    hash = hash + num.substring(num.length - 3, num.length);
    return hash;
  }
  return (
    <>
      <div style={{ padding: "1px 0px 0px" }}>
        <div
          style={{
            fontWeight: 600,
            color: "#454545",
            fontSize: "0.938vw",
            margin: "1.146vw 1.563vw 1.250vw",
          }}
        >
          Bank account details
          {/* {console.log(userId,'userId in return')} */}
        </div>
        <hr style={{ marginTop: "0px", marginBottom: "0px" }}></hr>
        <div className="PrimaryScrollQualification33">
          {bankDetail?.map((detail) =>
            detail?.status === "Rejected" ? null : (
              <>
                <Media className="align-items-center">
                  <Link>
                    <div
                      style={{
                        display: "flex",
                        height: "1.458vw",
                        width: "1.302vw",
                        margin: "0px 0.469vw 0.469vw 0px",
                        justifyContent: "center",
                      }}
                    >
                      {detail?.isApproved === true ? (
                        <img
                          alt="..."
                          src={
                            require("../../assets/img/icons/common/BankLogoBlue.png")
                              .default
                          }
                        />
                      ) : (
                        <img
                          alt="..."
                          src={
                            require("../../assets/img/icons/common/BankLogoGray.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </Link>
                  {""}
                  {detail?.isApproved === true ? (
                    <a
                      style={{
                        color: "#5E72E4",
                        fontWeight: 600,
                        fontSize: "0.729vw",
                        marginBottom: "0.573vw",
                      }}
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {detail?.bankName}
                    </a>
                  ) : (
                    <a
                      style={{
                        color: "#9A9A9A",
                        fontWeight: 600,
                        fontSize: "0.729vw",
                        marginBottom: "0.573vw",
                      }}
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {detail?.bankName}
                    </a>
                  )}
                </Media>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        // minWidth: '12.604vw',
                        fontWeight: "bold",
                        color: "#454545",
                      }}
                      className="mb-0 text-sm"
                    >
                      {props?.user?.user?.name}
                    </span>
                    {detail?.status === "Approved" ? (
                      <div
                        style={{
                          paddingLeft: "2% ",
                          marginTop: "-0.573vw",
                          height: "0.833vw",
                          width: "100px",
                          display: "flex",
                          justifyContent: "space-around",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "0.625vw",
                            color: "#fff",
                            backgroundColor: "#12BF0F",
                            height: "1.146vw",
                            width: "100px",
                            paddingTop: "3px",
                          }}
                          className="namebutton"
                        >
                          Verified
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* {detail?.status === "Approved" ? ( */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      flexDirection: "row",
                      float: "right",
                    }}
                  >
                    <i
                      onClick={() => {
                        setSelectedBankDetail(detail);
                        setOpen(true);
                      }}
                      style={{ color: "grey" }}
                      className="far fa-eye"
                    ></i>
                    &nbsp;&nbsp;
                    <i
                      onClick={() => {
                        api.deleteBankDetail(selectedBankDetail._id);
                        getBankAccountDetails(userId);
                        setOpen1(false);
                      }}
                      style={{ color: "grey" }}
                      className="far fa-trash-alt"
                    ></i>
                  </div>
                  {/* ) : (
                    <div style={{ float: "right" }}>
                      <a
                        className="mb-0 text-sm"
                        href="#pablo"
                        onClick={() => {
                          setSelectedBankDetail(detail);
                          setOpen1(true);
                        }}
                      >
                        Verify
                      </a>
                    </div>
                  )} */}
                </div>
                <Media className="align-items-center">
                  <Media
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#454545",
                      }}
                      className="mb-0 text-xs"
                    >
                      {/* {detail?.accountNumber} */}
                      {hashNumber(String(detail?.accountNumber))}
                    </span>
                  </Media>
                </Media>
                <hr></hr>
              </>
            )
          )}
        </div>
      </div>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        style={{ maxWidth: "621px", maxHeight: "470px" }}
        toggle={() => setOpen(!isOpen)}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <i
              size="lg"
              style={{
                color: "#FF8A3C",
                paddingRight: "0.208vw",
                fontSize: "2.604vw",
                marginBottom: "0px",
                marginRight: "1.146vw",
              }}
            >
              <img
                alt="..."
                src={
                  require("../../assets/img/icons/common/GroupBigBank.png")
                    .default
                }
              />
            </i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "5px",
                textAlign: "left",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "1.250vw",
                    fontWeight: 600,
                    color: "#333333",
                  }}
                >
                  {" "}
                  Bank Account
                </div>
                <i
                  style={{
                    marginLeft: "16vw",
                    paddingTop: "3px",
                    color: "#9A9A9A",
                  }}
                  onClick={() => setOpen(false)}
                >
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/GroupCross.png")
                        .default
                    }
                  />{" "}
                </i>
              </div>

              <div
                style={{
                  color: "#9A9A9A",
                  textAlign: "left",
                  fontSize: "0.729vw",
                  fontWeight: 400,
                }}
              >
                Showing bank details of tutor
              </div>
            </div>
          </div>
        </CardHeader>
        <div
          style={{
            backgroundColor: "#F6F9FC",
            paddingTop: "1.563vw",
            paddingBottom: "1.563vw",
            paddingLeft: "1.563vw",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Holder Name{" "}
              </div>
              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {props?.user?.user?.name}
              </div>
            </div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginTop: "0.521vw",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Account Number
              </div>

              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {" "}
                {selectedBankDetail?.accountNumber}
              </div>
            </div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginTop: "0.521vw",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Bank Name
              </div>
              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {selectedBankDetail?.bankName}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            float: "right",
            paddingTop: "1.042vw",
            marginLeft: "74%",
            marginBottom: "1.042vw",
          }}
        >
          <button
            style={{
              width: "143px",
              height: "47px",
              fontFamily: "open-sans",
              lineHeight: "0.990vw",
              display: "flex",
              alignItems: "center",
              border: "1px solid #F5365C",
              justifyContent: "center",
              backgroundColor: "#FFFFFF",
              color: "#F5365C",
              fontSize: "0.729vw",
              fontWeight: 700,
              borderRadius: "0.573vw",
              // marginBottom:"35px",
              marginRight: "1.563vw",
              boxShadow:
                "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 0.208vw 0.573vw rgba(50, 50, 93, 0.1)",
            }}
            onClick={() => {
              api.deleteBankDetail(selectedBankDetail._id);
              getBankAccountDetails(userId);
              setOpen(false);
            }}
            type="button"
          >
            Remove
          </button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen1}
        style={{ maxWidth: "37.396vw", maxHeight: "32.344vw" }}
        toggle={() => setOpen1(!isOpen1)}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <i
              size="lg"
              style={{
                color: "#FF8A3C",
                paddingRight: "0.208vw",
                fontSize: "2.604vw",
                marginBottom: "0px",
                marginRight: "1.146vw",
              }}
            >
              <img
                alt="..."
                src={
                  require("../../assets/img/icons/common/GroupBigBank.png")
                    .default
                }
              />
            </i>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginLeft: "5px",
                textAlign: "left",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "5px",
                }}
              >
                <div
                  style={{
                    fontSize: "1.250vw",
                    fontWeight: 600,
                    color: "#333333",
                  }}
                >
                  {" "}
                  Verify Bank Details Request{" "}
                </div>
                <i
                  style={{
                    marginLeft: "13vw",
                    paddingTop: "3px",
                    color: "#9A9A9A",
                  }}
                  onClick={() => setOpen1(false)}
                >
                  <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/GroupCross.png")
                        .default
                    }
                  />{" "}
                </i>
              </div>

              <div
                style={{
                  color: "#9A9A9A",
                  textAlign: "left",
                  fontSize: "0.729vw",
                  fontWeight: 400,
                }}
              >
                Cross check the bank details with attached proof and respond
              </div>
            </div>
          </div>
        </CardHeader>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            backgroundColor: "#F6F9FC",
            padding: "1.250vw 1.563vw",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Holder name{" "}
              </div>
              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {" "}
                {selectedBankDetail?.accountHolder}
              </div>
            </div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginTop: "0.521vw",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Account Number
              </div>
              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {" "}
                {hashNumber(String(selectedBankDetail?.accountNumber))}
              </div>
            </div>
            <div>
              <div
                style={{
                  color: "#757575",
                  marginTop: "0.521vw",
                  marginBottom: "1px",
                  fontSize: "0.729vw",
                  fontWeight: 600,
                }}
              >
                Bank Name
              </div>
              <div
                style={{
                  color: "#454545",
                  fontWeight: "bold",
                  fontSize: "0.938vw",
                  fontWeight: 700,
                }}
              >
                {selectedBankDetail?.bankName}
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "100px",
              fontSize: "0.729vw",
              fontWeight: 700,
            }}
          >
            REVIEW ATTACHMENT
            <div
              className="con"
              style={{ marginTop: "0.573vw", borderRadius: "12px" }}
            >
              <div className="overlay">
                <p style={{ fontSize: "0.833vw", fontWeight: "700" }}>
                  BankDetails.pdf
                </p>
                <a href={selectedBankDetail?.document} target="_blank">
                  <p
                    style={{
                      fontSize: "0.729vw",
                      color: "#FFCB95",
                      fontWeight: "400",
                    }}
                  >
                    {selectedBankDetail?.document}
                    (Click to review)
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{ float: "right", paddingTop: "15px", marginLeft: "80%" }}
          >
            <button
              style={{
                width: "107px",
                height: "47px",
                display: "flex",
                fontFamily: "open-sans",
                lineHeight: "0.990vw",
                alignItems: "center",
                border: "0px",
                justifyContent: "center",
                backgroundColor: "#2DCE89",
                color: "#FFFFFF",
                fontSize: "0.729vw",
                fontWeight: 700,
                borderRadius: "0.573vw",
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 0.208vw 0.573vw rgba(50, 50, 93, 0.1)",
              }}
              onClick={() => {
                approveBankDetail(selectedBankDetail._id, {
                  status: "Approved",
                });
                setOpen1(false);
              }}
            >
              Approve
            </button>
          </div>
          <div>
            <div
              style={{
                marginLeft: "1.302vw",
                fontSize: "0.729vw",
                fontWeight: 600,
                color: "#454545",
                marginBottom: "0.417vw",
              }}
            >
              Reason of Rejection (Optional)
            </div>
            <div
              style={{
                marginLeft: "1.302vw",
                marginBottom: "0.521vw",
                width: "185%",
                height: "50%",
              }}
            >
              <FormGroup
                style={{ width: "50%", height: "20%" }}
                className="mb-0"
              >
                <Input
                  onChange={(e) => setMessage(e.target.value)}
                  type="textarea"
                  style={{ wordWrap: "break-word", height: "100px" }}
                />
              </FormGroup>
            </div>
          </div>
          <div
            style={{
              float: "right",
              paddingTop: "1.042vw",
              marginLeft: "80%",
              marginBottom: "1.042vw",
            }}
          >
            <button
              style={{
                width: "5.573vw",
                height: "2.448vw",
                fontFamily: "open-sans",
                lineHeight: "0.990vw",
                display: "flex",
                alignItems: "center",
                border: "0px",
                justifyContent: "center",
                backgroundColor: "#F5365C",
                color: "#FFFFFF",
                fontSize: "0.729vw",
                fontWeight: 700,
                borderRadius: "0.573vw",
                boxShadow:
                  "0px 1px 3px rgba(0, 0, 0, 0.08), 0px 0.208vw 0.573vw rgba(50, 50, 93, 0.1)",
                marginTop: "-0.521vw",
              }}
              onClick={() => {
                rejectBankDetail(selectedBankDetail._id, {
                  status: "Rejected",
                  message,
                });
                setOpen1(false);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BankAccontDetail;
