/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { useState, useEffect } from "react";

// core components
import Header from "components/Headers/Header.js";
import "./index.css";
import { Link } from "react-router-dom";
import { getData } from "react";
import api from "../../../services/api";
import { reactLocalStorage } from "reactjs-localstorage";
import ReactLoading from "react-loading";
import "./index.css";

// Mui Components Import
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import JobTerminationReasonModal from "./JobTerminationReasonModal";

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${"#FF6700"};
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 6px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${"#FFE7CE"};
  }

  &:focus {
    color: #ffffff;
    border-radius: 6px;
    outline: 2px solid ${"#333333"};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${"#FF6700"};
    color: ${"#FFFFFF"};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: ${"#FFF8F1"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const Tables = () => {
  const [tab, setTab] = useState(0);
  const [selectedAdmin, setAdmin] = useState("");
  const [allusers, setAllUsers] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [requestedUsers, setRequestedUsers] = useState([]);
  const [jobTermination, setJobTermination] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loggedUser, setLoggedInUser] = useState(false);
  // const [loggedUser, setUser] = useState(false);

  const [requests, setRequests] = useState([]);
  const [state, setState] = useState({
    jobTerminationTab: 0,
    jobTerminationStatus: "Pending",
    jobTerminationModalIsOpen: false,
    jobTerminationRejectReason: "",
    selectedJobId: "",
  });

  useEffect(async () => {
    const user = await reactLocalStorage.getObject("userData");
    setLoggedInUser(user);
    setIsLoading(true);
    await getAllAdmins();
    await getAllRequestedUsers();
    await getRequestForJobTermination();
    await getRequests(user);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getRequestForJobTermination();
  }, [state.jobTerminationStatus]);

  async function getAllUsers(selectedAdmin, tabName) {
    try {
      let userType = "";
      if (tabName === 1) {
        userType = "Tutor";
      } else if (tabName === 2) {
        userType = "Tutee";
      }
      const allUsers = await api.getAllUsers(
        `?adminId=${selectedAdmin}&userType=${userType}&jobDetail=true`
      );
      setAllUsers(allUsers.data);
    } catch (e) {
      console.log(e.response);
    }
  }

  async function getAllAdmins() {
    try {
      const allAdmins = await api.getAllUsers("?userType=Admin");
      if (!allAdmins.length) {
        await setAllAdmins(allAdmins.data);
        await setAdmin(allAdmins.data[0]._id);
        await getAllUsers(allAdmins.data[0]._id, 0);
      }
    } catch (e) {
      console.log(e.response);
    }
  }

  async function getRequests(user) {
    try {
      const requests = await api.getQualification(null, "?status=Pending");
      if (!requests.length) {
        await setRequests(requests.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  }

  async function getAllRequestedUsers() {
    try {
      const requestedUsers = await api.getAllUsers("");
      if (!requestedUsers.length) {
        await setRequestedUsers(requestedUsers.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  }

  async function getRequestForJobTermination() {
    try {
      const jobTermination = await api.getJobCancelRequests(
        `?status=${state.jobTerminationStatus}`
      );

      setIsLoading(false);

      if (!jobTermination.length) {
        await setJobTermination(jobTermination.data);
      }
    } catch (e) {
      console.log(e.response);
    }
  }
  function getCurrentDateTime() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    // Zero-padding the hours and minutes if needed
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    const formattedDateTime = `${formattedHours}:${formattedMinutes} - ${day} ${month}, ${year}`;
    return formattedDateTime;
  }

  async function viewProfile(user, type) {
    const data = {
      adminId: loggedUser._id,
      userId: user._id,
      activityType: "View Profile",
      message: type,
      dateTime: getCurrentDateTime(),
    };
    await api.postActivitylog(data);
  }
  async function changeStatus(id, status) {
    try {
      const jobTermination = await api.jobTerminationRequest(id, { status });
      getRequestForJobTermination();
    } catch (e) {
      console.log(e.response);
    }
  }

  async function changeStatusAccepet(id) {
    try {
      const jobTermination = await api.jobTerminationAccept(id);
      getRequestForJobTermination();
    } catch (e) {
      console.log(e.response);
    }
  }

  async function changeStatusReject(id) {
    const { selectedJobId, jobTerminationRejectReason } = state;

    const payload = {
      rejectionReason: jobTerminationRejectReason,
    };

    try {
      await api.jobTerminationReject(selectedJobId, payload);
      setIsLoading(true);
      jobTerminationModalHanlder();
      getRequestForJobTermination();
    } catch (e) {
      console.log(e.response);
    }
  }

  const handleJobTerminationTab = (tabId = 0) => {
    let status;

    switch (tabId) {
      case 0:
        status = "Pending";
        break;
      case 1:
        status = "Accepted";
        break;

      case 2:
        status = "Declined";
        break;

      default:
    }

    setState((prev) => ({
      ...prev,
      jobTerminationTab: tabId,
      jobTerminationStatus: status,
    }));

    setIsLoading(true);
  };

  const jobTerminationModalHanlder = (jobId) => {
    setState((prev) => ({
      ...prev,
      jobTerminationModalIsOpen: !prev.jobTerminationModalIsOpen,
      selectedJobId: jobId ?? prev.selectedJobId,
    }));
  };

  const size = useWindowSize();

  return (
    <div>
      <Header />
      <hr className="lead-hr" />
      <Container
        className={window.innerWidth <= 1366 ? "mt--8" : "mt--9"}
        fluid
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card className="shadow lead-ShadowCard">
              <CardHeader className="lead-cardHeader">
                <div className="lead-mainCont">
                  <h3 className="mb-0 lead-userCard-heading">User Under</h3>
                  {/* <FormControl>
                  <InputLabel id="demo-simple-select-label">Admin</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    className="dropdown lead-dropdown"
                    label="Admin"
                    onChange={handleSelect}
                  >
                    {allAdmins.map((admin) => (
                    <MenuItem value={admin.id}>{admin.name}</MenuItem>
                      ))}
                  </Select>
                  </FormControl> */}
                  <div
                    onChange={async (e) => {
                      await setAdmin(e.target.value);
                      await getAllUsers(e.target.value, tab);
                    }}
                    className="dropdown lead-dropdown"
                  >
                    <select name="cars" id="cars" className="lead-dropdown-btn">
                      {allAdmins.map((admin) => (
                        <option
                          value={admin._id}
                          className="lead-dropdown-option"
                        >
                          {admin.name.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="lead-userChange">
                  <TabsUnstyled defaultValue={0} className="unstyledTab">
                    <TabsList>
                      <Tab
                        onClick={async () => {
                          await setTab(0);
                          await getAllUsers(selectedAdmin, 0);
                        }}
                      >
                        All
                      </Tab>
                      <Tab
                        onClick={async () => {
                          await setTab(1);
                          await getAllUsers(selectedAdmin, 1);
                        }}
                      >
                        Tutor
                      </Tab>
                      <Tab
                        onClick={async () => {
                          await setTab(2);
                          await getAllUsers(selectedAdmin, 2);
                        }}
                      >
                        Tutee
                      </Tab>
                    </TabsList>
                  </TabsUnstyled>
                </div>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      {" "}
                      NAME
                    </th>
                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      TYPE
                    </th>

                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      TOTAL
                    </th>

                    <th
                      scope="col"
                      style={{
                        fontSize: 12,
                        fontWeight: 700,
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                      }}
                    >
                      ACTIVE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="lead-tr">
                      <td colSpan={3}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : allusers.length > 0 ? (
                    allusers.map((user) => (
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              style={{ height: "30px", width: "30px" }}
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              {user.photoUrl ? (
                                <img
                                  className="rounded-circle"
                                  src={user.photoUrl}
                                  style={{
                                    width: 38,
                                    height: 38,
                                  }}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    width: 38,
                                    height: 38,
                                    backgroundColor: "#ced4da",
                                  }}
                                  alt={user.name}
                                  src="/"
                                />
                              )}
                            </a>
                            <Media className="lead-userName">
                              <Link
                                to={
                                  user.userType === "Tutor"
                                    ? `/admin/Tutor/${user._id}`
                                    : `/admin/Tutee/${user._id}`
                                }
                                onClick={() => {
                                  user.userType === "Tutor"
                                    ? viewProfile(user, "tutor")
                                    : viewProfile(user, "Tutee");
                                }}
                              >
                                {user.name}
                              </Link>
                              {/* <a
                                style={{ fontSize: "85%" }}
                                href={
                                  user.userType === "Tutor"
                                    ? `/admin/Tutor/${user._id}`
                                    : `/admin/Tutee/${user._id}`
                                }
                              >
                                {user.name}
                              </a> */}
                            </Media>
                          </Media>
                        </th>
                        <td
                          style={{ color: "#9A9A9A" }}
                          className="lead-userName"
                        >
                          {user.userType}
                        </td>
                        <td
                          style={{ color: "#9A9A9A" }}
                          className="lead-userName"
                        >
                          {user.jobDetails.total ?? 0}
                        </td>
                        <td
                          style={{ color: "#9A9A9A" }}
                          className="lead-userName"
                        >
                          {user.jobDetails.ongoing ?? 0}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ width: "100%" }}>
                      <td colSpan={3}>
                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          No Users Found
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card
              className="shadow"
              style={{ maxHeight: "500px", minHeight: "500px" }}
            >
              <CardHeader className="border-0">
                <h3 className="mb-0 lead-alltutorHeader">
                  All tutors qualification request
                </h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead>
                  <tr>
                    <th scope="col" className="thead-light">
                      {" "}
                      NAME
                    </th>
                    <th scope="col" className="thead-light">
                      STATUS
                    </th>
                    <th scope="col" className="thead-light">
                      DATE
                    </th>
                    <th scope="col" className="thead-light">
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="lead-tr2">
                      <td colSpan={5}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <ReactLoading
                            type={"spin"}
                            color={"#ff6700"}
                            height={30}
                            width={30}
                          />
                        </div>
                      </td>
                    </tr>
                  ) : requests.length > 0 ? (
                    requests.map((req) => (
                      <tr>
                        <th scope="row">
                          <Link
                            to={`/admin/Tutor/${req?.tutorId}`}
                            onClick={() => {
                              viewProfile(
                                {
                                  _id: req?.tutorId,
                                },
                                "tutor"
                              );
                            }}
                          >
                            {req?.tutorName || "N/A"}
                          </Link>
                        </th>
                        <td
                          style={{ color: "#9A9A9A" }}
                          className="lead-userName"
                        >
                          {req?.status}
                        </td>
                        <td
                          style={{ color: "#9A9A9A" }}
                          className="lead-userName"
                        >
                          {req.createdAt.substring(0, 10)}
                        </td>
                        <td>
                          <Link
                            style={{ fontSize: "85%" }}
                            to={`/admin/Tutor/${req?.tutorId}`}
                            onClick={() => {
                              viewProfile(
                                {
                                  _id: req?.tutorId,
                                },
                                "tutor"
                              );
                            }}
                          >
                            <Badge
                              color="success"
                              pill
                              style={{ fontSize: "12px", width: 50 }}
                            >
                              View
                            </Badge>
                          </Link>
                          {/* <Link
                            to={
                              req.user.userType === "Tutor"
                                ? `/admin/Tutor/${req.user._id}`
                                : `/admin/Tutee/${req.user._id}`
                            }
                          >
                            <Badge
                              color="success"
                              pill
                              style={{ fontSize: "60%" }}
                            >
                              View
                            </Badge>
                          </Link> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr style={{ width: "100%" }}>
                      <td colSpan={4}>
                        <p style={{ fontSize: "12px", textAlign: "center" }}>
                          No Users Found
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Card
          className="shadow"
          style={{ maxHeight: "500px", minHeight: "500px", marginTop: "30px" }}
        >
          <CardHeader className="border-0" style={{ minHeight: "60px" }}>
            <h3 className="mb-0">All request for job termination</h3>

            <div className="lead-userChange">
              <TabsUnstyled defaultValue={0} className="unstyledTab">
                <TabsList>
                  <Tab
                    onClick={async () => {
                      handleJobTerminationTab(0);
                    }}
                  >
                    Pending
                  </Tab>
                  <Tab
                    onClick={async () => {
                      handleJobTerminationTab(1);
                    }}
                  >
                    Accepted
                  </Tab>
                  <Tab
                    onClick={async () => {
                      handleJobTerminationTab(2);
                    }}
                  >
                    Declined
                  </Tab>
                </TabsList>
              </TabsUnstyled>
            </div>
          </CardHeader>

          <Table className="align-items-center " responsive>
            <thead className="thead-light">
              <tr>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600, width: "25%" }}
                >
                  {" "}
                  BY
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600, width: "25%" }}
                >
                  TUITION
                </th>
                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600, width: "20%" }}
                >
                  Reason
                </th>

                <th
                  style={{ fontSize: "0.625vw", fontWeight: 600, width: "20%" }}
                >
                  Status
                </th>

                {state.jobTerminationStatus === "Declined" && (
                  <th
                    style={{
                      fontSize: "0.625vw",
                      fontWeight: 600,
                      width: "20%",
                    }}
                  >
                    Rejection Reason
                  </th>
                )}
                <th style={{ fontSize: "0.625vw", fontWeight: 600 }}></th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr className="lead-tr2">
                  <td colSpan={12}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <ReactLoading
                        type={"spin"}
                        color={"#ff6700"}
                        height={30}
                        width={30}
                      />
                    </div>
                  </td>
                </tr>
              ) : jobTermination.length > 0 ? (
                jobTermination.map((user) => (
                  <tr style={{ borderBottom: "1px solid #E9ECEF" }}>
                    <td style={{ border: "none" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ fontSize: "12px" }}>
                          <div className="lable">{user?.sender?.[0]?.userType}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <Link
                            style={{ fontWeight: "bold", fontSize: "90%" }}
                            className="mb-0 "
                            to={
                              user.sender?.[0]?.userType === "Tutor"
                                ? `/admin/Tutor/${user?.sender?.[0]?._id}`
                                : `/admin/Tutee/${user?.sender?.[0]?._id}`
                            }
                          >
                            {user?.sender?.[0]?.name}
                          </Link>

                          <span style={{ fontSize: "80%", color: "#9A9A9A" }}>
                            {user.createdAt.substring(0, 10)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td style={{ border: "none" }}>
                      <div className="lead_adminDetailCont">
                        {user?.job[0]?.courseName || "N/A"}
                        <span style={{ fontSize: "80%", color: "#9A9A9A" }}>
                          {user?.job[0]?.courseLvl}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="text-break">
                        <p className="lead-detailP">{user.reason || "N/A"}</p>
                      </div>
                    </td>

                    <td>
                      <div className="text-break">
                        <p className="lead-detailP">
                          {state.jobTerminationStatus}
                        </p>
                      </div>
                    </td>
                    {state.jobTerminationStatus === "Declined" && (
                      <td>{user.rejectionReason || "N/A"}</td>
                    )}
                    <td style={{ width: "10%", border: "none" }}>
                      {state.jobTerminationTab === 0 && (
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => changeStatusAccepet(user.jobId)}
                            >
                              Approve
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                jobTerminationModalHanlder(user.jobId);
                              }}
                            >
                              Decline
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr style={{ width: "100%" }}>
                  <td colSpan={4}>
                    <p style={{ fontSize: "12px", textAlign: "center" }}>
                      No Users Found
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
        <br></br>
        <br></br>

        <JobTerminationReasonModal
          isOpen={state.jobTerminationModalIsOpen}
          handleModal={jobTerminationModalHanlder}
          performDeclineOperation={() =>
            changeStatusReject(state.selectedJobId)
          }
          handleMessage={(message) =>
            setState((prev) => ({
              ...prev,
              jobTerminationRejectReason: message,
            }))
          }
        />
      </Container>
    </div>
  );
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default Tables;
