import Multiselect from "multiselect-react-dropdown";
import React from "react";
import styles from "./styles.module.scss";

const MultiSelectDropdown = ({
  title = "Add Tutee",
  options,
  onSelect,
  selectedValues = null,
  isObject = true,
  disablePreSelectedValues = false,
}) => {
  return (
    <div>
      <Multiselect
        className={styles.selectField}
        placeholder={title}
        disablePreSelectedValues={disablePreSelectedValues}
        options={options}
        isObject={isObject}
        selectedValues={selectedValues ? selectedValues : []}
        onSelect={onSelect}
        onRemove={onSelect}
        displayValue={"name"}
      />
    </div>
  );
};

export default MultiSelectDropdown;
