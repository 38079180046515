/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { useState, useEffect } from "react";

// core components
import Header from "components/Headers/Header.js";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import ReactLoading from "react-loading";
import Avatar from "@mui/material/Avatar";
import "./index.css";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { isEmpty } from "lodash";
import moment from "moment";

// /////// Mui Styles for tabs starts here
const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${"#FF6700"};
  cursor: pointer;
  font-size: 16px;
  height: 34px;
  font-weight: bold;
  // background-color: transparent;
  background-color: ${"#FFF8F1"};
  width: 100%;
  margin: 0.313vw 0.5vw;
  border: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${"#FFE7CE"};
  }

  &:focus {
    color: #ffffff;
    border-radius: 6px;
    outline: 2px solid ${"#333333"};
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${"#FF6700"};
    color: ${"#FFFFFF"};
    margin: 0.313vw 0.5vw;
    height: 34px;
    display: flex;
    align-items: center;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 20.042vw;
  background-color: ${"#FFF8F1"};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

// /////// Mui Styles for tabs ends here

const Tables = (props) => {
  const [tab, setTab] = useState(0);
  const size = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(async () => {
    getAllUsers(tab);
  }, []);

  async function getAllUsers(tab) {
    setIsLoading(true);

    try {
      let userType = "";
      if (tab === 0) {
        userType = "Tutor";
      } else if (tab === 1) {
        userType = "Tutee";
      }
      const allUsers = await api.getAllUsers(
        `?userType=${userType}&jobDetail=true&reviewDetail=true`
      );
      // if (!allusers.length) {
      setAllUsers(allUsers.data);

      setIsLoading(false);

      // }
    } catch (e) {
      console.log(e?.response?.data?.message);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        className={window.innerWidth <= 1366 ? "mt--7" : "mt--8"}
        fluid
      >
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow" style={{ padding: "0px 0px" }}>
              <CardHeader
                style={{
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "70px",
                }}
              >
                <h3 className="mb-0" style={{ fontSize: "24px" }}>
                  Membership level of tutors
                </h3>
                <TabsUnstyled defaultValue={0} className="unstyledTab">
                  <TabsList>
                    <Tab
                      onClick={async () => {
                        setTab(0);
                        getAllUsers(0);
                      }}
                    >
                      Tutor
                    </Tab>
                    <Tab
                      onClick={async () => {
                        setTab(1);
                        getAllUsers(1);
                      }}
                    >
                      Tutee
                    </Tab>
                  </TabsList>
                </TabsUnstyled>
              </CardHeader>
            </Card>
            <Row
              style={{
                marginTop: "20px",
                marginLeft: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col
                xs={12}
                md={12}
                lg={4}
                xl={size.width > 1200 && size.width < 1800 ? 4 : 3}
                sm={12}
                style={{
                  height: "68vh",
                  padding: "0px 0px",
                  width: "24%",
                  flex: "none",
                }}
              >
                <div
                  className="col"
                  style={{ height: "100%", padding: "0px 0px" }}
                >
                  <Card
                    style={{ width: "100%", height: "100%" }}
                    className="shadow"
                  >
                    <CardHeader
                      className="border-0 mt-0 mb-0"
                      style={{
                        backgroundColor: "#F6F9FC",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        minHeight: "90px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h3 style={{ fontSize: "20px" }} className="mb-0">
                          Member
                        </h3>
                        <span
                          style={{
                            color: "grey",
                            fontSize: "75%",
                            lineHeight: "1.3",
                          }}
                        >
                          Megamind User
                        </span>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                        className="buttonStyle1"
                      >
                        <i
                          className="fa fa-user-circle"
                          style={{ fontSize: "1.5em", color: "#FF9044" }}
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                            marginLeft: "2px",
                          }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            paddingRight: "5px",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star"
                        ></i>
                      </div> */}
                    </CardHeader>
                    <div
                      style={{
                        backgroundColor: "#D6FFE9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        // padding: "5px 0px",
                      }}
                    >
                      <p></p>

                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        Dedicated Phone Support
                      </p>
                      <p></p>
                    </div>
                    <Table
                      style={{ weight: "20%" }}
                      className="align-items-center table-flush"
                      responsive
                    >
                      <tbody className="mem-tbScroll">
                        {isLoading ? (
                          <tr
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "10rem",
                            }}
                          >
                            <td colSpan={9}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ReactLoading
                                  type={"spin"}
                                  color={"#ff6700"}
                                  height={30}
                                  width={30}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          allUsers.map((user) =>
                            tab === 0 ? (
                              user.userType === "Tutor" &&
                              user.membershipStatus === "Member" && (
                                <tr
                                  key={user._id}
                                  style={{ cursor: "pointer" }}
                                >
                                  <MembershipUserDetail user={user} />
                                </tr>
                              )
                            ) : isLoading ? (
                              <tr
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  marginLeft: "10rem",
                                }}
                              >
                                <td colSpan={9}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ff6700"}
                                      height={30}
                                      width={30}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              user.userType === "Tutee" &&
                              user.membershipStatus === "Member" && (
                                <tr style={{ cursor: "pointer" }}>
                                  <MembershipUserDetail user={user} />
                                </tr>
                              )
                            )
                          )
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Col>

              <Col
                xs={12}
                md={12}
                lg={4}
                xl={size.width > 1200 && size.width < 1800 ? 4 : 3}
                sm={12}
                style={{
                  height: "68vh",
                  padding: "0px 0px",
                  width: "24%",
                  flex: "none",
                }}
              >
                <div
                  className="col"
                  style={{ height: "100%", padding: "0px 0px" }}
                >
                  <Card
                    style={{ width: "100%", height: "100%" }}
                    className="shadow"
                  >
                    <CardHeader
                      className="border-0 mt-0 mb-0"
                      style={{
                        backgroundColor: "#E9ECEF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "90px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h3 style={{ fontSize: "20px" }} className="mb-0">
                          Silver
                        </h3>
                        <span
                          style={{
                            color: "grey",
                            fontSize: "75%",
                            lineHeight: "1.3",
                          }}
                        >
                          Members with Silver tier
                        </span>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                        className="buttonStyle1"
                      >
                        <i
                          className="fa fa-user-circle"
                          style={{ fontSize: "1.5em", color: "#FF9044" }}
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                            marginLeft: "2px",
                          }}
                          className="fa fa-star checked"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            paddingRight: "5px",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star"
                        ></i>
                      </div> */}
                    </CardHeader>
                    <div
                      style={{
                        backgroundColor: "#D6FFE9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "5px 0px",
                      }}
                    >
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        Dedicated Phone Support
                      </p>
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        5% Off in Megamind shop
                      </p>
                    </div>
                    <Table
                      style={{ weight: "20%" }}
                      className="align-items-center table-flush"
                      responsive
                    >
                      <tbody className="mem-tbScroll">
                        {isLoading ? (
                          <tr
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "10rem",
                            }}
                          >
                            <td colSpan={9}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ReactLoading
                                  type={"spin"}
                                  color={"#ff6700"}
                                  height={30}
                                  width={30}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          allUsers.map((user) =>
                            tab === 0 ? (
                              user.userType === "Tutor" &&
                              user.membershipStatus === "Silver" ? (
                                <tr
                                  key={user._id}
                                  style={{ cursor: "pointer" }}
                                >
                                  <MembershipUserDetail user={user} />
                                </tr>
                              ) : null
                            ) : isLoading ? (
                              <tr
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  marginLeft: "10rem",
                                }}
                              >
                                <td colSpan={9}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ff6700"}
                                      height={30}
                                      width={30}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : user.userType === "Tutee" &&
                              user.membershipStatus === "Silver" ? (
                              <tr style={{ cursor: "pointer" }}>
                                <MembershipUserDetail user={user} />
                              </tr>
                            ) : null
                          )
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Col>

              <Col
                xs={12}
                md={12}
                lg={4}
                xl={size.width > 1200 && size.width < 1800 ? 4 : 3}
                sm={12}
                style={{
                  height: "68vh",
                  padding: "0px 0px",
                  width: "24%",
                  flex: "none",
                }}
              >
                <div
                  className="col"
                  style={{ height: "100%", padding: "0px 0px" }}
                >
                  <Card
                    style={{ width: "100%", height: "100%" }}
                    className="shadow"
                  >
                    <CardHeader
                      className="border-0 mt-0 mb-0"
                      style={{
                        backgroundColor: "#FFDA64",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "90px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h3 style={{ fontSize: "20px" }} className="mb-0">
                          Gold
                        </h3>
                        <span
                          style={{
                            color: "grey",
                            fontSize: "75%",
                            lineHeight: "1.3",
                          }}
                        >
                          Members with Gold tier
                        </span>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                        className="buttonStyle1"
                      >
                        <i
                          className="fa fa-user-circle"
                          style={{ fontSize: "1.5em", color: "#FF9044" }}
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                            marginLeft: "2px",
                          }}
                          className="fas fa-star checked"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star checked"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            paddingRight: "5px",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star"
                        ></i>
                      </div> */}
                    </CardHeader>
                    <div
                      style={{
                        backgroundColor: "#D6FFE9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "5px 0px",
                      }}
                    >
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        Dedicated Phone Support
                      </p>
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        10% Off on Megamind shop
                      </p>
                    </div>
                    <Table
                      style={{ weight: "20%" }}
                      className="align-items-center table-flush"
                      responsive
                    >
                      <tbody className="mem-tbScroll">
                        {isLoading ? (
                          <tr
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "10rem",
                            }}
                          >
                            <td colSpan={9}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ReactLoading
                                  type={"spin"}
                                  color={"#ff6700"}
                                  height={30}
                                  width={30}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          allUsers.map((user) =>
                            tab === 0 ? (
                              user.userType === "Tutor" &&
                              user.membershipStatus === "Gold" ? (
                                <tr
                                  key={user._id}
                                  style={{ cursor: "pointer" }}
                                >
                                  <MembershipUserDetail user={user} />
                                </tr>
                              ) : null
                            ) : isLoading ? (
                              <tr
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  marginLeft: "10rem",
                                }}
                              >
                                <td colSpan={9}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ff6700"}
                                      height={30}
                                      width={30}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : user.userType === "Tutee" &&
                              user.membershipStatus === "Gold" ? (
                              <tr style={{ cursor: "pointer" }}>
                                <MembershipUserDetail user={user} />
                              </tr>
                            ) : null
                          )
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Col>

              <Col
                xs={12}
                md={12}
                lg={4}
                xl={size.width > 1200 && size.width < 1800 ? 4 : 3}
                sm={12}
                style={{
                  height: "68vh",
                  padding: "0px 0px",
                  width: "24%",
                  flex: "none",
                }}
              >
                <div
                  className="col"
                  style={{ height: "100%", padding: "0px 0px" }}
                >
                  <Card
                    style={{ width: "100%", height: "100%" }}
                    className="shadow"
                  >
                    <CardHeader
                      className="border-0 mt-0 mb-0"
                      style={{
                        backgroundColor: "#C9D7FF",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "90px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <h3 style={{ fontSize: "20px" }} className="mb-0">
                          Diamond
                        </h3>
                        <span
                          style={{
                            color: "grey",
                            fontSize: "75%",
                            lineHeight: "1.3",
                          }}
                        >
                          Members with Diamond tier
                        </span>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px",
                        }}
                        className="buttonStyle1"
                      >
                        <i
                          className="fa fa-user-circle"
                          style={{ fontSize: "1.5em", color: "#FF9044" }}
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                            marginLeft: "2px",
                          }}
                          className="fas fa-star checked"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star checked"
                        ></i>
                        <i
                          style={{
                            paddingTop: "5px",
                            color: "#BDBDBD",
                            paddingRight: "5px",
                            fontSize: "0.9em",
                          }}
                          className="fas fa-star checked"
                        ></i>
                      </div> */}
                    </CardHeader>
                    <div
                      style={{
                        backgroundColor: "#D6FFE9",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "5px 0px",
                      }}
                    >
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        Dedicated Phone Support
                      </p>
                      <p
                        style={{
                          fontSize:
                            size.width > 1200 && size.width < 1800
                              ? "60%"
                              : "75%",
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                      >
                        <i
                          style={{
                            fontSize:
                              size.width > 1200 && size.width < 1800
                                ? "60%"
                                : "75%",
                            padding: "0.5vw",
                            color: "#12BF0F",
                          }}
                          className="fas fa-check"
                        ></i>
                        15% Off in Megamind shop
                      </p>
                    </div>
                    <Table
                      style={{ weight: "20%" }}
                      className="align-items-center table-flush"
                      responsive
                    >
                      <tbody className="mem-tbScroll">
                        {isLoading ? (
                          <tr
                            style={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "10rem",
                            }}
                          >
                            <td colSpan={9}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <ReactLoading
                                  type={"spin"}
                                  color={"#ff6700"}
                                  height={30}
                                  width={30}
                                />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          allUsers.map((user) =>
                            tab === 0 ? (
                              user.userType === "Tutor" &&
                              user.membershipStatus === "Diamond" ? (
                                <tr
                                  key={user._id}
                                  style={{ cursor: "pointer" }}
                                >
                                  <MembershipUserDetail user={user} />
                                </tr>
                              ) : null
                            ) : isLoading ? (
                              <tr
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  marginLeft: "10rem",
                                }}
                              >
                                <td colSpan={9}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ReactLoading
                                      type={"spin"}
                                      color={"#ff6700"}
                                      height={30}
                                      width={30}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ) : user.userType === "Tutee" &&
                              user.membershipStatus === "Diamond" ? (
                              <tr style={{ cursor: "pointer" }}>
                                <MembershipUserDetail user={user} />
                              </tr>
                            ) : null
                          )
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </>
  );
};
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default Tables;

const MembershipUserDetail = ({ user }) => {
  return (
    <Media className="align-items-center membershipUser">
      {user?.photoUrl ? (
        <img
          className="rounded-circle"
          src={user?.photoUrl}
          style={{
            width: 38,
            height: 38,
          }}
        />
      ) : (
        <Avatar
          style={{
            width: 38,
            height: 38,
            backgroundColor: "#ced4da",
          }}
          alt={user?.name}
          src="/"
        />
      )}

      <Media>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            marginLeft: "10px",
          }}
        >
          <Link
            className="mb-0 "
            style={{
              fontSize: "100%",
              fontWeight: 600,
            }}
            to={`/admin/${user.userType}/${user._id}`}
          >
            {user?.name}
          </Link>
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <div>
              <span className="membershioPointsTitle"> Points: </span>
              <span className="membershioPointsValue">
                {user?.membershipPoints}
              </span>
            </div>
            {!isEmpty(user?.membershipExpiry) && (
              <div>
                <span className="membershioPointsTitle"> Expiry Date: </span>
                <span className="membershioPointsValue">
                  {moment(user?.membershipExpiry).format("DD MMM, YYYY")}
                </span>
              </div>
            )}
          </div>
        </div>
      </Media>
    </Media>
  );
};
