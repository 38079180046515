import { createContext, useEffect, useContext, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "./firebase-config";
import { BASE_URL } from "services/Path";

export const ConversationServiceContext = createContext({
  messages: [],
  isLoading: true,
});

const ConversationServiceProvider = ({ children }) => {
  const { job } = children.props;
  const [messages, setMessages] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  // console.log(job,'jobjobjobjobjob')
  const tutee = job?.tuteeId;
  const tutor = job?.tutorId;
  const jobId = job?._id;
  // console.log(tutee,'tuteetuteetutee')
  // console.log(tutor,'tutortutortutor')
  let folderName = BASE_URL?.includes("dev")
    ? "Dev-Conversations"
    : "Live-Conversations";

  const getMessagesForchat = () => {
    const q = query(
      collection(db, folderName, jobId, jobId),
      orderBy("timestamp", "asc")
    );

    const messagesSnapShot = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc, index) => {
        const entry = doc.data();
        return {
          content: entry.content,
          idFrom: entry.idFrom,
          idTo: entry.idTo,
          timestamp: entry.timestamp,
          photoUrl2: entry.photoUrl2,
          type: entry.type,
        };
      });

      setMessages(data);
      setisLoading(false);
    });

    return messagesSnapShot;
  };
  useEffect(() => {
    setisLoading(true);
    const messagesSnapShot = getMessagesForchat();
    setisLoading(false);

    return () => {
      messagesSnapShot();
    };
  }, []);
  return (
    <ConversationServiceContext.Provider
      value={{
        messages,
        isLoading,
      }}
    >
      {children}
    </ConversationServiceContext.Provider>
  );
};
export const conversationService = () => useContext(ConversationServiceContext);

export default ConversationServiceProvider;
