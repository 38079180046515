import InputField from "components/InputField";
import RadioButtonGroup from "components/RadioField";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, CardBody, CardHeader, Modal, Spinner } from "reactstrap";

const RescheduleClassModal = ({
  loading,
  event,
  isOpen = false,
  updateSchedule,
  handleRescheduleModal,
}) => {
  const [state, setState] = useState({
    rescheduleType: "Both",
    newTime: null,
    newDate: event?.newDate
      ? event?.newDate
      : event?.oldDate
      ? event?.oldDate
      : event?.date,
  });

  const setMinDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    return formattedDate;
  };

  const handleRescheduleType = (type) => {
    setState((prev) => ({
      ...prev,
      rescheduleType: type,
    }));
  };

  const helperFormatDate = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const handleUpdateSchedule = () => {
    const { rescheduleType, newDate, newTime } = state;
    let hasError = false,
      modalData;

    switch (rescheduleType) {
      case "Both": {
        if (isEmpty(newDate) || isEmpty(newTime)) {
          hasError = true;
        }

        modalData = {
          rescheduleType,
          newDate,
          newTime,
        };
        break;
      }
      case "Date": {
        if (isEmpty(newDate)) {
          hasError = true;
        }
        modalData = {
          rescheduleType,
          newDate,
        };
        break;
      }
      case "Time": {
        if (isEmpty(newTime)) {
          hasError = true;
        }
        modalData = {
          rescheduleType,
          newTime,
        };
        break;
      }

      default:
        return;
    }

    if (hasError) {
      return toast.error("Please enter updated schedule first");
    } else {
      updateSchedule(modalData, event);
    }
  };
  return (
    <div>
      <Modal
        className="modal-dialog-centered reschedule-modal"
        isOpen={isOpen}
        size="lg"
        toggle={handleRescheduleModal}
      >
        <CardHeader className="border-0">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <h1 style={{ marginBottom: "-2px", textAlign: "center" }}>
              {" "}
              Reschedule Class{" "}
            </h1>

            <span
              style={{
                color: "#9A9A9A",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              Choose from below options to reschedule a class
            </span>
          </div>
        </CardHeader>
        <CardBody>
          <div>
            <h4 style={{ marginBottom: "", textAlign: "" }}>
              {" "}
              Reschedule Type{" "}
            </h4>
            <RadioButtonGroup
              selectedOption={state.rescheduleType}
              onChange={handleRescheduleType}
              options={[
                { label: "Both", value: "Both" },
                { label: "Date", value: "Date" },
                { label: "Time", value: "Time" },
              ]}
            />
          </div>
          {["Both", "Date"].includes(state.rescheduleType) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "45%" }}>
                <label className="rescheduleFieldLabel">From Date</label>
                <div className="rescheduleFieldValue">
                  {helperFormatDate(
                    event?.newDate
                      ? event?.newDate
                      : event?.oldDate
                      ? event?.oldDate
                      : event?.date
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <i
                  style={{ color: "#9A9A9A" }}
                  className="fas fa-arrow-right"
                ></i>
              </div>
              <div style={{ width: "45%" }}>
                <InputField
                  label={"To Date"}
                  type="date"
                  min={setMinDate()}
                  value={state?.newDate}
                  placeholder="YYYY-MM-DD"
                  onChange={(event) => {
                    setState((prev) => ({
                      ...prev,
                      newDate: event.target?.value,
                    }));
                  }}
                />
              </div>
            </div>
          )}

          {["Both", "Time"].includes(state.rescheduleType) && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: state.rescheduleType === "Both" ? "20px" : "0px",
              }}
            >
              <div style={{ width: "45%" }}>
                <label className="rescheduleFieldLabel">From Time</label>
                <div className="rescheduleFieldValue">
                  {event?.newTime ? event?.newTime : event?.oldTime}
                </div>
              </div>
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <i
                  style={{ color: "#9A9A9A" }}
                  className="fas fa-arrow-right"
                ></i>
              </div>

              <div style={{ width: "45%", display: "flex", gap: "10px" }}>
                <div style={{ width: "100%" }}>
                  <InputField
                    label={"To Start Time"}
                    type="time"
                    onChange={(event) => {
                      setState((prev) => ({
                        ...prev,
                        newTime: event.target?.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </CardBody>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0px 10px 20px 15px",
          }}
        >
          <Button
            // disabled={loading}
            style={{
              width: "100%",
              height: "50px",
              paddingTop: "8px",
              fontWeight: "bold",
            }}
            className="modalFooter publishVoucher"
            onClick={handleUpdateSchedule}
          >
            {`Update Schedule`}
            {loading && (
              <Spinner
                style={{
                  width: 16,
                  height: 16,
                  marginLeft: "5px",
                }}
              />
            )}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default RescheduleClassModal;
