import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import InputField from "components/InputField";
import RadioButtonGroup from "components/RadioField";
import { Button, Modal } from "reactstrap";
import Dropdown from "components/Dropdown";
import MultiSelectDropdown from "components/MultiSelectDropdown";

// hello HELLO
const CreateVoucherModal = ({
  err,
  setErr,
  state,
  isOpen = false,
  setOpen,
  setPrice,
  setMinSpend,
  publishTo,
  setState,
  setDate,
  allTutees,
  allPendingTutees,
  setAllPendingTutees,
  onSelect,
  postVoucher,
  publishToList,
  isVoucherActive,
  selectedTutee,
  setVoucherName,
  selectedVoucher,
  setSelectedVoucher,
  handleVoucherStatus,
  setShortDescription,
  handleSelectChange,
  isAutoAdd,
  setIsAutoAdd,
  handleTuteeChange,
}) => {
  const setMinDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${yyyy}-${mm}-${dd}`;

    return formattedDate;
  };

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
      toggle={() => {
        setOpen(!isOpen);
        setAllPendingTutees(allTutees);
        if (state.isEditMode) {
          // setSelectedVoucher({});
          setState((prev) => ({
            ...prev,
            isEditMode: false,
          }));
        }
      }}
    >
      <div style={{ padding: "25px 25px 10px" }}>
        <div style={{ textAlign: "center" }}>
          <h1>{`${state.isEditMode ? "Update" : "Create"} Voucher`}</h1>
          <p style={{ color: "#9A9A9A" }}>
            {" "}
            {`${state.isEditMode ? "" : "Create and publish a new voucher"}`}
          </p>
        </div>
        <div className="voucher-modal-data">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                max={24}
                label={
                  <div className="shortDescription">
                    <div>Voucher Title</div>
                    <div className="shortDescCount">Max 24 characters</div>
                  </div>
                }
                type="text"
                // disabled={selectedVoucher?.autoAddNewUser ? true : false}
                // label="Voucher Title"
                value={selectedVoucher["title"]}
                placeholder="Enter voucher title"
                onChange={(e) => setVoucherName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                type="textarea"
                max={60}
                label={
                  <div className="shortDescription">
                    <div>Short Description</div>
                    <div className="shortDescCount">Max 60 characters</div>
                  </div>
                }
                // disabled={selectedVoucher?.autoAddNewUser ? true : false}
                value={selectedVoucher["shortDescription"]}
                placeholder="Enter short description"
                onChange={(e) => setShortDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <h5>Select Tutee Type</h5>
              </div>
              <div>
                <Dropdown
                  name="publish"
                  value={publishTo}
                  disabled={state.isEditMode ? true : false}
                  // options={[
                  //   { label: "Is Global", value: "Is Global" },
                  //   { label: "Choose tutee", value: "Choose tutee" },
                  // ]}
                  options={[
                    { value: "ALL_TUTEE", label: "All Tutee" },
                    { value: "CUSTOM_TUTEE", label: "Custom Tutee" },
                    {
                      value: "ALL_TUTEE_MEMBER",
                      label: "Tutee with Membership (Member)",
                    },
                    {
                      value: "ALL_TUTEE_SILVER",
                      label: "Tutee with Membership (Silver)",
                    },
                    {
                      value: "ALL_TUTEE_GOLD",
                      label: "Tutee with Membership (Gold)",
                    },
                    {
                      value: "ALL_TUTEE_DIAMOND",
                      label: "Tutee with Membership (Diamond)",
                    },
                  ]}
                  onChange={handleSelectChange}
                />
              </div>
              {/* {publishTo === "Is Global" && ( */}
              {publishTo === "CUSTOM_TUTEE" && (
                <div className="voucher-choose-tutee">
                  <MultiSelectDropdown
                    title="Add Tutee"
                    selectedValues={publishToList ? publishToList : []}
                    options={allPendingTutees}
                    onSelect={onSelect}
                    // disablePreSelectedValues={state.isEditMode ? true : false}
                  />
                </div>
              )}
              {publishTo === "ALL_TUTEE" && (
                <div style={{ paddingTop: "10px" }}>
                  <input
                    onChange={(e) => {
                      setIsAutoAdd(!isAutoAdd);
                    }}
                    type="checkbox"
                    style={{ marginTop: "10px" }}
                    name="All Tutee"
                    value={false}
                    checked={isAutoAdd ? true : false}
                  />
                  <label style={{ marginLeft: "10px" }}>
                    Auto add new tutee users to this voucher
                  </label>
                </div>
              )}
            </Grid>

            <Grid item xs={4}>
              <InputField
                prefix="$"
                label="Price"
                type="number"
                placeholder="Price"
                value={selectedVoucher["price"]}
                disabled={selectedVoucher["price"]}
                onChange={(e) => {
                  setErr("");
                  setPrice(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                prefix="$"
                type="number"
                label="Min Spend Amount"
                placeholder="Enter Amount"
                value={selectedVoucher["minSpend"]}
                onChange={(e) => {
                  setErr("");
                  setMinSpend(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                label="Expiry Date"
                type="date"
                min={setMinDate()}
                value={selectedVoucher["expiryDate"]}
                onChange={(e) => {
                  setErr("");
                  setDate(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: "10px" }}>
          <RadioButtonGroup
            selectedOption={isVoucherActive}
            onChange={handleVoucherStatus}
            options={[
              { label: "Active", value: "Active" },
              { label: "In Active", value: "InActive" },
            ]}
          />
        </div>

        {err && (
          <p style={{ textAlign: "center", color: "red", marginTop: "20px" }}>
            {err}
          </p>
        )}

        <div className="modal-footer">
          <Button
            disabled={state.isLoading}
            style={{ width: "100%", height: "40px", paddingTop: "8px" }}
            className="modalFooter publishVoucher"
            onClick={() => postVoucher()}
          >
            {`${state.isEditMode ? "Update Voucher" : "Publish Voucher"}`}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateVoucherModal;
