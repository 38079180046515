import React from "react";
import { FormGroup, Label, Input, Form } from "reactstrap";

import styles from "./styles.module.scss";

function RadioButtonGroup({ options, selectedOption, onChange }) {
  return (
    <Form>
      <FormGroup tag="fieldset" className={styles.radioBlock}>
        {options.map((option, index) => (
          <FormGroup
            check
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Label check>
              <Input
                type="radio"
                name="radioOption"
                style={{marginTop: "0.28rem !important"}}
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => onChange(option.value)}
              />
              {option.label}
            </Label>
          </FormGroup>
        ))}
      </FormGroup>
    </Form>
  );
}

export default RadioButtonGroup;
