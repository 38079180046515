import axios from "axios";
import { Path } from "./Path";
import {
  getWithAuth,
  postWithAuth,
  patchWithAuth,
  deleteWithAuth,
  getWithAuthBody
} from "./axiosHelperFunctions";
const login = async (payLoad) => {
  const { data } = await axios.post(`${Path.LOGIN}`, payLoad);
  return data;
};
const getAllUsers = async (query) => {
  const data = await getWithAuth(`${Path.GET_ALL_USERS}${query}`);
  return data;
};
const getUserById = async (id, query) => {
  const data = await getWithAuth(`${Path.GET_ALL_USERS_BY_ID}/${id}${query}`);
  return data;
};
const postNewUser = async (payload) => {
  const data = await postWithAuth(`${Path.GET_ALL_USERS_BY_ID}`, payload);
  return data;
};

const editUserByAdmin = async (payload, id) => {
  // const data = await patchWithAuth(`${Path.GET_ALL_USERS_BY_ID}`, payload);
  const data = await patchWithAuth(
    `${Path.GET_ALL_USERS_BY_ID}/${id}`,
    payload
  );

  return data;
};

const editUser = async (id, body) => {
  const data = await patchWithAuth(`${Path.GET_ALL_USERS_BY_ID}/${id}`, body);
  return data;
};

const resetPassword = async (email, body) => {
  const data = await patchWithAuth(
    `${Path.GET_ALL_USERS_BY_ID}/resetPassword/${email}`,
    body
  );
  return data;
};

const bulkCharge = async (body) => {
  const data = await patchWithAuth(`${Path.BULK_CHARGE}/`, body);
  return data;
};

const getUserSchedule = async (query) => {
  const data = await getWithAuth(`${Path.GET_USER_SCHEDULE}${query}`);
  return data;
};
const getAllJobs = async (query) => {
  const data = await getWithAuth(`${Path.GET_ALL_JOBS}${query}`);
  return data;
};
const getAllRequests = async (id) => {
  const data = await getWithAuth(`${Path.GET_ALL_JOB_REQUESTS}/${id}`);
  return data;
};
const deleteJob = async (id, body) => {
  const data = await deleteWithAuth(`${Path.DELETE_JOB_BY_ID}/${id}`);
  return data;
};
const reschedule = async (id, payload) => {
  const data = await patchWithAuth(`${Path.RESCHEDULE}/${id}`, payload);
  return data;
};

const classReschedule = async (payload) => {
  const data = await postWithAuth(`${Path.CLASS_RESCHEDULE}`, payload);
  return data;
};
const checkClassRescheduleOverlap = async (payload) => {
  const data = await getWithAuthBody(`${Path.CLASS_RESCHEDULE}/checkClassRescheduleOverlap`, payload);
  return data;
};

const classDeleteRequest = async (payload) => {
  const data = await postWithAuth(`${Path.CLASS_DELETE_REQUEST}`, payload);
  return data;
};

const postActivitylog = async (payload) => {
  const data = await postWithAuth(`${Path.ACTIVITY_LOG}`, payload);
  return data;
};
const getAllActivitylog = async (query) => {
  const data = await getWithAuth(`${Path.ACTIVITY_LOG}${query}`);
  return data;
};
const addCourse = async (payload) => {
  const data = await postWithAuth(`${Path.GET_ALL_COURSES}`, payload);
  return data;
};
const getAllCourses = async (query) => {
  const data = await getWithAuth(`${Path.GET_ALL_COURSES}${query}`);
  return data;
};
const updateCourse = async (id, payload) => {
  const data = await patchWithAuth(`${Path.GET_ALL_COURSES}/${id}`, payload);
  return data;
};
const getPushNotification = async (query) => {
  const data = await getWithAuth(`${Path.GET_ALL_PUSHNOTIFICATION}${query}`);
  return data;
};

const postPushNotification = async (payload) => {
  const data = await postWithAuth(`${Path.GET_ALL_PUSHNOTIFICATION}`, payload);
  return data;
};

const addQualification = async (body) => {
  const data = await postWithAuth(`${Path.QUALIFICATION}`, body);
  return data;
};
const editQualification = async (id, body) => {
  const data = await patchWithAuth(`${Path.QUALIFICATION}/${id}`, body);
  return data;
};
const deleteQualification = async (id, body) => {
  const data = await deleteWithAuth(`${Path.QUALIFICATION}/${id}`);
  return data;
};
const getQualification = async (id, queryParams = "") => {
  const path = id
    ? `${Path.QUALIFICATION}/id`
    : `${Path.QUALIFICATION}${queryParams}`;
  const data = await getWithAuth(path);
  return data;
};
const getBankAccountDetails = async (query) => {
  const data = await getWithAuth(`${Path.GET_BANK_ACCOUNT_DETAILS}${query}`);
  return data;
};
const editBankDetail = async (id, body) => {
  const data = await patchWithAuth(
    `${Path.GET_BANK_ACCOUNT_DETAILS}/${id}`,
    body
  );
  return data;
};

const deleteBankDetail = async (id, body) => {
  const data = await deleteWithAuth(`${Path.GET_BANK_ACCOUNT_DETAILS}/${id}`);
  return data;
};
const getJobById = async (id) => {
  const data = await getWithAuth(`${Path.GET_JOB_BY_ID}/${id}`);
  return data;
};

const getRequests = async (id = "") => {
  const url = id ? `${Path.REQUESTS}/${id}` : `${Path.REQUESTS}`;
  const data = await getWithAuth(url);
  return data;
};

const getJobCancelRequests = async (query) => {
  const data = await getWithAuth(`${Path.JOB_CANCEL_REQUESTS}${query}`);
  return data;
};

const changeStatus = async (id, status) => {
  const data = await patchWithAuth(`${Path.JOB_CANCEL_REQUESTS}/${id}`, status);
  return data;
};

const jobTerminationRequest = async (id, status) => {
  const data = await patchWithAuth(
    `${Path.JOB_TERMINATION_REQUEST}/${id}`,
    status
  );
  return data;
};

const jobTerminationAccept = async (id) => {
  const data = await patchWithAuth(`${Path.JOB_TERMINATION_ACCEPT}/${id}`);
  return data;
};

const jobTerminationReject = async (id, payload) => {
  const data = await patchWithAuth(
    `${Path.JOB_TERMINATION_REJECT}/${id}`,
    payload
  );
  return data;
};

const getBilling = async (query = "") => {
  const data = await getWithAuth(`${Path.BILLING}${query}`);
  return data;
};
const getBankBilling = async (id) => {
  const data = await getWithAuth(`${Path.GET_BANK_BILLING_REQUEST}/${id}`);
  return data;
};
const editBankBilling = async (id, body) => {
  const data = await patchWithAuth(`${Path.BANK_BILLING_REQUEST}/${id}`, body);
  return data;
};
const editBankBillingRejected = async (id, body) => {
  const data = await patchWithAuth(
    `${Path.BANK_BILLING_REQUEST_REJECTED}/${id}`,
    body
  );
  return data;
};
const getSalaries = async (query) => {
  const data = await getWithAuth(`${Path.SALARIES}${query}`);
  return data;
};
const getTuitionStats = async () => {
  const data = await getWithAuth(`${Path.TUITION_STATS}`);
  return data;
};
const getDashboardStats = async () => {
  const data = await getWithAuth(`${Path.DASHBOARD_STATS}`);
  return data;
};

const postVoucher = async (payload) => {
  const data = await postWithAuth(`${Path.VOUCHER}`, payload);
  return data;
};

const updateVoucher = async (payload, id = "") => {
  const data = await patchWithAuth(`${Path.VOUCHER}/${id}`, payload);
  return data;
};

const splitVoucher = async (id, payload) => {
  const data = await patchWithAuth(
    `${Path.VOUCHER}/splitVoucher/${id}`,
    payload
  );
  return data;
};

const getVouchers = async (queryParams = "") => {
  const data = await getWithAuth(`${Path.VOUCHER}${queryParams}`);
  return data;
};

const deleteVoucher = async (id) => {
  const data = await deleteWithAuth(`${Path.VOUCHER}/${id}`);
  return data;
};

const editVouchers = async (id, payload) => {
  const data = await patchWithAuth(`${Path.VOUCHER}/${id}`, payload);
  return data;
};
const getReview = async (query) => {
  const data = await getWithAuth(`${Path.GET_REVIEW}${query}`);
  return data;
};
const snedNotification = async (body) => {
  const data = await postWithAuth(`${Path.USER_NOTIFICATION}`, body);
  return data;
};
const emailVerification = async (id) => {
  const data = await postWithAuth(`${Path.VERIFY_EMAIL}/${id}`);
  return data;
};

const checkTokenExpiry = async (token) => {
  const data = await axios.post(`${Path.CHECK_EXPIRY}`, {
    token,
  });
  return data;
};

export default {
  login,
  getAllUsers,
  getAllJobs,
  getAllRequests,
  deleteJob,
  getAllActivitylog,
  addCourse,
  getAllCourses,
  getPushNotification,
  getUserById,
  getUserSchedule,
  editUser,
  editQualification,
  deleteQualification,
  addQualification,
  getQualification,
  getBankAccountDetails,
  editBankDetail,
  deleteBankDetail,
  reschedule,
  classReschedule,
  getJobById,
  getRequests,
  getJobCancelRequests,
  deleteVoucher,
  changeStatus,
  postActivitylog,
  getBilling,
  getSalaries,
  getTuitionStats,
  getDashboardStats,
  postVoucher,
  updateVoucher,
  getVouchers,
  editVouchers,
  updateCourse,
  getReview,
  editBankBilling,
  editBankBillingRejected,
  getBankBilling,
  snedNotification,
  emailVerification,
  splitVoucher,
  editUserByAdmin,
  classDeleteRequest,
  bulkCharge,
  postNewUser,
  resetPassword,
  checkTokenExpiry,
  jobTerminationRequest,
  jobTerminationAccept,
  jobTerminationReject,
  postPushNotification,
  checkClassRescheduleOverlap
};
